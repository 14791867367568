import React, { useState, useCallback, useEffect } from "react";

import { Button, TextField, Snackbar } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useHistory as useHistoryHook } from "react-router-dom";
import VirtualizedTable from './VirtualizedTable';

import "./ClickupData.css";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  input: {
    display: "none",
  },
  h2: {
    marginTop: 20,
    marginLeft: -100,
  },
  upload: {
    marginTop: 30,
    marginLeft: -20,
    marginBottom: 20,
  },
  buttons: {
    marginBottom: 20,
  },
  button: {
    margin: theme.spacing(1),
    width: "150px",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 400,
    overflow: "auto",
  },
  tableCell: {
    padding: "4px 8px",
    lineHeight: "1.5",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflowing text
    textOverflow: "ellipsis", // Add ellipsis for overflowing text
  },

  // fileNameContainer: {
  //   // display: 'flex',
  //   alignItems: 'center',
  //   marginLeft: theme.spacing(2),
  // },
  // fileName: {
  //   marginLeft: theme.spacing(1),
  //   color: "#555"
  // },
  errorRow: {
    backgroundColor: "#ffcccc",
  },
  createdRow: {
    backgroundColor: "rgba(0, 255, 0, 0.2)",
  },
  updatedRow: {
    backgroundColor: "#FFFFE0",
  },
});

const ClickUpData = ({ classes }) => {
  const history = useHistoryHook();
  const [departmentId, setDepartmentId] = useState(null);
  const [activeTab, setActiveTab] = useState("clickup");
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSecondTab, setShowSecondTab] = useState(false);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  // Error Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //Snackbar for no changes
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  // Success Snackbar state
  const [snackbarsucessOpen, setSnackbarsuccessOpen] = useState(false);
  const [snackbarsuccessMessage, setSnackbarsuccessMessage] = useState("");

  const [uploading, setUploading] = useState(false); // Loader for file upload
  const [loadSubmit, setloadSubmit] = useState(false); //Loader for file submit
  const [resData, setResData] = useState({
    num_rows_inserted: 0,
    num_rows_updated: 0,
  });

  // Fetch projects and update state

  const handleUploadTabClick = () => {
    setActiveTab("clickup");
  };

  const handleViewTabClick = () => {
    setActiveTab("clickup2");
    history.push("/estimate-data");
  };

  useEffect(() => {
    fetchDepartmentData();
    handleProjectDetails();
    // Fetch department data on initial mount
  }, []);

  const fetchDepartmentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/user-view`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      setDepartmentId(data.department_id);
      setShowSecondTab(data.department_id === 19);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    }
  };

  const fetchProjects = async () => {
    try {
      setLoadingProjects(true);
      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/clickup-project-details`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setLoadingProjects(false);
    }
  };

  //Calling fetchprojects on initial mount
  useEffect(() => {
    handleProjectDetails();
  }, []);

  const handleProjectDetails = async () => {
    setIsLoading(true);
    try {
      await fetchProjects();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const preprocessRows = (rows) => {
    return rows.map((row) => {
      return Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          if (
            typeof value === "string" &&
            ["nan", "na", "none"].includes(value.toLowerCase())
          ) {
            return [key, ""];
          }
          return [key, value];
        })
      );
    });
  };
  const onSubmitFile = useCallback(
    async (event) => {
      const cleanedRows = preprocessRows(rows);
      function fetchTimeout(url, options = {}, timeout = 900000) { // default to 15 minutes
        return new Promise((resolve, reject) => {
          // Create the timeout promise
          const timeoutId = setTimeout(() => {
            reject(new Error('Request timed out'));
          }, timeout);
      
          // Perform the fetch request
          fetch(url, options)
            .then(response => {
              clearTimeout(timeoutId); // Clear the timeout if fetch completes
              resolve(response);
            })
            .catch(error => {
              clearTimeout(timeoutId); // Clear the timeout in case of an error
              reject(error);
            });
        });
      }
      
      const response = await fetchTimeout(
          `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/clickup-submit-data/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ cleanedRows }),
          },
          900000 // 15 minutes in milliseconds
        );
  
      const result = await response.json();
      setResData(result);
      return result;
    },
    [rows]
  );

  const handleSubmitProjects = async () => {
    setloadSubmit(true);
    try {
      const result = await onSubmitFile();
      // Create maps for error, created, and updated rows
      const errorMap = new Map(
        result.errors.map((e) => [e.task_id, { ...e, status_task: "error" }])
      );
      const createdMap = new Map(
        result.results
          .filter((r) => r.status_task === "created")
          .map((r) => [r.task_id, { ...r, status_task: "created" }])
      );
      const updatedMap = new Map(
        result.results
          .filter((r) => r.status_task === "updated")
          .map((r) => [r.task_id, { ...r, status_task: "updated" }])
      );

      // Combine results from maps, prioritizing errors
      const combinedResults = [
        ...errorMap.values(),
        ...createdMap.values(),
        ...updatedMap.values(),
      ];

      // Update rows with combined results
      const updatedRows = rows.map((row) => {
        const updatedRow = combinedResults.find(
          (res) => res.task_id === row.task_id
        );
        if (updatedRow) {
          return { ...row, ...updatedRow };
        }
        return row;
      });

      setRows(updatedRows);
      
      if (!hasErrors() && result.errors.length == 0) {
        setSnackbarsuccessOpen(true);
        setSnackbarsuccessMessage(
          "Project details have been uploaded successfully."
        );
        setSubmitButtonDisabled(true);
        setSelectedProjects([]);
      }
      else if (result.errors.length > 0) {
        // Show snackbar for errors
        setSnackOpen(true);
        setSnackbarText("No Changes Done");
      } 
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
      setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
    } finally {
      setloadSubmit(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleSuccessSnackbar = () => {
    setSnackbarsuccessOpen(false);
  };

  const handleChangeProjects = (event, newValue) => {
    setSelectedProjects(newValue);
    setRows([]);
    setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
    setUploadedFileName("");
  };

  const onFileUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (fileExtension !== "xlsx") {
          setSnackbarOpen(true);
          setSnackbarMessage(`${file.name} format is incorrect.`);
          return;
        }
        setUploading(true);
        setRows([]);
        setUploadedFileName(file.name);
        const formData = new FormData();
        formData.append("xlsx_file", file);
        const selectedProjectids = selectedProjects.map(
          (project) => project.id
        );
        formData.append("project_ids", JSON.stringify(selectedProjectids));

        const response = await fetch(
          `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/clickup-upload-data/`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const responseData = await response.json();

        let resData = [];
        let errorData = [];
        let errorFound = false;

        if (!responseData.status && responseData.message === "Error Occured") {
          resData = responseData.data;
          errorFound = true;
          errorData.push("Incorrect column sequence or date format.");
        } else if (
          !responseData.status &&
          responseData.message === "Server Error"
        ) {
          errorFound = true;
          errorData.push("Something went wrong, Server Error");
        } else if (!responseData.status || responseData.status == 400) {
          errorFound = true;
          errorData.push(responseData.message);
        } else {
          resData = responseData && responseData.data ? responseData.data : [];
        }

        if (errorFound) {
          setSnackbarOpen(true);
          setSnackbarMessage(errorData);
          setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
        } else {
          setSnackbarsuccessOpen(true);
          // setSnackbarsuccessMessage("File uploaded successfully.");
          setSnackbarsuccessMessage("Kindly review and Submit the Data.");
          setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
        }
        setRows(resData);
        setUploading(false);
        setSubmitButtonDisabled(false);
      }
    },
    [selectedProjects]
  );

  const hasErrors = () => {
    return rows.some((row) => row.error && row.error.trim() !== "");
  };

  const hasErrorsSubmit = () => {
    return rows.some((row) => row.error && row.error.trim() !== "");
  };

  const handleTabAClick = () => {
    setActiveTab({ tabA: true, tabB: false });
  };

  const handleTabBClick = () => {
    setActiveTab({ tabA: false, tabB: true });
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "calibri",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "black",
          textAlign: "center",
          marginTop: "2px",
          margin: "8px 0",
          marginBottom: "2px",
          padding: "5px",
        }}
      >
        Clickup Data Upload
      </Typography>
      <div className={classes.tabs} style={{ marginBottom: 20 }}>
        <div
          className={classes.tabs}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            gap: "7px",
          }}
        >
          <Button
            style={{ padding: "10px" }}
            variant={activeTab === "clickup" ? "contained" : "outlined"}
            color="primary"
            onClick={handleUploadTabClick}
          >
            Clickup Data
          </Button>

          {showSecondTab && (
            <Button
              style={{ padding: "10px" }}
              variant={activeTab === "clickup2" ? "contained" : "outlined"}
              color="primary"
              onClick={handleViewTabClick}
            >
              Project Estimate
            </Button>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      {uploading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <div className="project-details">
        <Autocomplete
          multiple
          options={projects}
          style={{ width: 400, marginLeft: 400, marginTop: 15, alignContent: 'center' }}
          noOptionsText={"No records found"}
          getOptionLabel={(option) => option.display_name}
          value={selectedProjects}
          onChange={handleChangeProjects}
          loading={loadingProjects}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Select Projects"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.display_name}
                {...getTagProps({ index })}
                key={option.id} // Assuming each option has a unique `id`
                style={{ marginRight: 4, marginBottom: 4 }} // Adjust as needed
              />
            ))
          }
          sx={{
            width: 600,
            marginLeft: 400,
            marginTop: 30,
            '& .MuiAutocomplete-tag': {
              marginRight: 4,
              marginBottom: 4,
            },
            '& .MuiAutocomplete-paper': {
              maxHeight: 200, // Adjust height as needed
              overflowY: 'auto',
            },
          }}
        />
      </div>
      <div className={classes.upload}>
        <input
          accept=".xlsx"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={(event) => {
            onFileUpload(event);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
          disabled={selectedProjects.length === 0}
        />
        <label htmlFor="contained-button-file">
          <Button
            startIcon={<PublishIcon />}
            variant="contained"
            color="primary"
            component="span"
            disabled={selectedProjects.length === 0}
          >
            Upload
          </Button>
        </label>
      </div>
      {/* {uploadedFileName && (
        <div className={classes.fileNameContainer}>
          <span className={classes.fileName}>{uploadedFileName}</span>
        </div>
      )} */}
      {/* Snackbar for errors */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <MuiAlert onClose={handleCloseSnack} severity="info" variant="filled">
          {snackbarText}
        </MuiAlert>
      </Snackbar>

      {/* <TableContainer className= {classes.tableContainer} component={Paper} style={{ marginTop: 20 }}> */}
      {/* <Table key ={rows.length} className={classes.table} aria-label="simple table"> */}
      {/* <TableHead>
        <TableRow style={{ backgroundColor: "#8ed6d7" }}>
        <TableCell className={classes.tableCell}>TM CID</TableCell>
            <TableCell className={classes.tableCell}>TM PID</TableCell>
            <TableCell className={classes.tableCell}>TM STL ID</TableCell>
            <TableCell className={classes.tableCell}>Client's Name</TableCell>
            <TableCell className={classes.tableCell}>Project Name</TableCell>
            <TableCell className={classes.tableCell}>Sub Project Name</TableCell>
            <TableCell className={classes.tableCell}>Task ID</TableCell>
            <TableCell className={classes.tableCell}>Task Name</TableCell>
            <TableCell className={classes.tableCell}>Status</TableCell>
            <TableCell className={classes.tableCell}>Assignee</TableCell>
            <TableCell className={classes.tableCell}>Priority</TableCell>
            <TableCell className={classes.tableCell}>Due Date</TableCell>
            <TableCell className={classes.tableCell}>Start Date</TableCell>
            <TableCell className={classes.tableCell}>Date Created</TableCell>
            <TableCell className={classes.tableCell}>Date Closed</TableCell>
            <TableCell className={classes.tableCell}>Date Done</TableCell>
            <TableCell className={classes.tableCell}>Time Logged</TableCell>
            <TableCell className={classes.tableCell}>Time Estimate</TableCell>
            <TableCell className={classes.tableCell}>Tag</TableCell>
            <TableCell className= {classes.tableCell}>No of records processed</TableCell>
            <TableCell className={classes.tableCell}>Error</TableCell>
          </TableRow>
        </TableHead> */}
      {/* <TableBody> */}
     
      <VirtualizedTable rows={rows} classes={classes} loading={loading} />

      {/* } */}
      {/* <TableFooter>
            <TableRow>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer> */}
      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: "20px" }}>
        <span className='boldText'>Rows Created: {resData["num_rows_inserted"]}</span>
        <span className='boldText'>Rows Updated: {resData["num_rows_updated"]}</span>
      </div>
      {loadSubmit ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarsucessOpen}
        autoHideDuration={5000}
        onClose={handleSuccessSnackbar}
      >
        <MuiAlert onClose={handleSuccessSnackbar} severity="success" variant="filled">
          {snackbarsuccessMessage}
        </MuiAlert>
      </Snackbar>

      {/* Submit Button */}
      <Button
        onClick={handleSubmitProjects}
        variant="contained"
        color="primary"
        disabled={rows.length === 0 || hasErrors() || isSubmitButtonDisabled}
        style={{
          align: "center",
          marginTop: 10,
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default withStyles(styles)(ClickUpData);
